<template>
  <section v-if="isNotEmptyItem" class="tender-modal-title mr-40 mt-20">
    <el-row type="flex" class="mb-15 fs-20 f-wrap">
      <el-col>
        <el-steps :space="20" direction="vertical" :active="1">
          <el-step :title="getTitle('load')" />
          <el-step :title="getTitle('unload')" />
        </el-steps>
      </el-col>
    </el-row>

    <el-row type="flex" class="mb-15 fs-16 f-wrap">
      <el-col>
        {{ item.distance ? `Расстояние: ${item.distance} км` : '' }},
        <span class="fw-b accepted">{{ item.product_name }}</span>
        <span>, период погрузки: </span>
        <span>
          {{ item.utime_load_start | toLocaleDateTime }}
        </span>
        -
        <span>
          {{ item.utime_load_stop | toLocaleDateTime }}
        </span>
      </el-col>
    </el-row>

    <el-row type="flex" class="mb-15 fs-16 f-wrap">
      <el-col>
        <span class="accepted">Общий объем </span>
        <span class="fw-b">
          {{ item.load_weight_total | numToLocaleString }}
        </span>
        т, в сутки
        <span>{{ item.load_weight_daily }}</span>
        т
      </el-col>
    </el-row>

    <el-row type="flex" class="mb-15 fs-16 f-wrap">
      <el-col class="title-left accepted">Дополнительное описание:</el-col>
      <el-collapse-transition>
        <el-col v-show="!isMobile || showDescription">
          {{ item.load_type_name }},
          {{ item.comment }}
        </el-col>
      </el-collapse-transition>
    </el-row>
    <el-row v-if="isMobile" class="mb-25">
      <el-col>
        <i-button
          :text="textBtn"
          width="190"
          fs="17"
          custom-class="small-btn"
          @click="showDescription = !showDescription"
        />
      </el-col>
    </el-row>
  </section>
</template>

<script>
import IButton from '@/components/Ui/Button'
import { addressPreparation } from '@/helpers/functions'

export default {
  name: 'TenderModalTitle',
  components: { IButton },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showDescription: true
    }
  },
  computed: {
    isNotEmptyItem() {
      return Object.keys(this.item).length > 0
    },
    textBtn() {
      return this.showDescription ? 'Скрыть' : 'Показать'
    }
  },
  watch: {
    isMobile(newVal) {
      this.showDescription = !newVal
    }
  },
  methods: {
    getTitle(type) {
      const {
        [`${type}_region`]: region,
        [`${type}_city`]: city,
        [`${type}_district`]: district,
        [`${type}_address`]: address
      } = this.item || {}

      return addressPreparation(region, district, city, address)
    }
  }
}
</script>

<style lang="sass">
@import '~@/assets/styles/mixins.sass'

.tender-modal-title
  color: white
  @include to(1024px)
    margin-right: 20px
  @include to(768px)
    margin-right: 10px

  .title-left
    max-width: 270px
    @include to(1024px)
      width: 100%
      max-width: 100%
      font-weight: bold
  &__info
    flex-wrap: wrap
  &__item
    font-size: 20px
    line-height: 23px
  @include to(1024px)
    .title-item-0
      order: 1
      width: 20%
      max-width: 20% !important
    .title-item-1
      order: 2
      width: 30%
      max-width: 30% !important
    .title-item-2
      order: 4
      width: 70%
      max-width: 70% !important
    .title-item-3
      order: 3
      width: 30%
      max-width: 30% !important
      margin-top: 20px
    .title-item-4
      order: 5
      width: 30%
      max-width: 30% !important
      margin-top: 20px

  @include to(768px)
    .title-item-0
      width: 30%
      max-width: 30% !important
    .title-item-1
      width: 70%
      max-width: 70% !important
    .title-item-2
      width: 100%
      max-width: 100% !important
      margin-top: 20px
    .title-item-3
      order: 3
      width: 100%
      max-width: 100% !important
      margin-top: 20px
    .title-item-4
      display: none
</style>
