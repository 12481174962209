<template>
  <section>
    <el-row class="fs-25 mb-50 blackDark">
      <span class="mr-20">Начальная цена:</span>
      <b v-if="item.price_start">{{ item.price_start }} р/т</b>
    </el-row>
    <el-row v-if="step === 0" class="mb-20">
      <tender-rate-info
        :id="item.id"
        :weight="item.load_weight_total | numToLocaleString"
        :finish="counter <= 0"
        :config="{ span: counter > 0 ? 16 : 23 }"
        :users="users"
        @toggle="step = 1"
      />
      <el-col :sm="{ span: 7, offset: 1 }" :xs="24" class="mt-45">
        <tender-counter :counter="counter" />
      </el-col>
    </el-row>
    <tender-vendor-participants
      v-else
      :id="item.id"
      :users="getList"
      :groups="item.groups_stats"
      @toggle="step = 0"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TenderCounter from './TenderCounter'
import TenderVendorParticipants from './TenderVendorParticipants'
import TenderRateInfo from './TenderRateInfo'
import { arrToObj } from '@/helpers/functions'

export default {
  name: 'TenderForVendor',
  components: {
    TenderRateInfo,
    TenderVendorParticipants,
    TenderCounter
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    counter: {
      type: Number,
      default: 0
    }
  },
  watch: {
    item: {
      immediate: true,
      handler(val) {
        if (val?.id) this.getApprovedUsers()
      }
    }
  },
  data() {
    return {
      step: 0
    }
  },
  computed: {
    ...mapGetters('approvedUsers', ['getList']),
    users() {
      return arrToObj(this.getList)
    }
  },
  methods: {
    ...mapActions('approvedUsers', ['FETCH']),
    getApprovedUsers() {
      this.FETCH({ for_tender: this.item.id, 'per-page': 999999 })
    }
  }
}
</script>
