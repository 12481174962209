<template>
  <el-col :sm="8" :xs="24" class="tender-rate">
    <el-collapse-transition>
      <div v-show="step === 1" class="mb-20 fs-16 blackDark">
        Автоторги позволяют меньше времени тратить на всю эту фигню с торгами,
        но много торговаться,
        <el-button type="text" class="td-n">подробнее</el-button>
      </div>
    </el-collapse-transition>
    <el-row type="flex" justify="space-between" class="fs-16 mb-20 blackDark">
      <span>Начальная цена:</span>
      <b v-if="item.price_start">{{ item.price_start }} р/т</b>
    </el-row>
    <el-row type="flex" justify="space-between" class="fs-16 mb-20 blackDark">
      <span>Текущая нижная цена:</span>
      <b v-if="item.counter_best_price">{{ item.counter_best_price }} р/т</b>
    </el-row>
    <el-form
      v-if="step !== 2"
      ref="tenderMakeRateForm"
      :model="form"
      :rules="rules"
      label-position="top"
      class="tender-form i-components blackDark"
      @submit.native.prevent
    >
      <el-form-item prop="price">
        <el-input-number
          v-model="form.price"
          :controls="false"
          :min="0"
          autocomplete="nope"
          :placeholder="config[step].left.input"
          class="w-100"
        />
      </el-form-item>
      <el-form-item prop="weight">
        <el-input-number
          v-model="form.weight"
          :controls="false"
          :min="0"
          autocomplete="nope"
          :placeholder="config[step].right.input"
          class="w-100"
        />
      </el-form-item>
      <el-row type="flex" justify="space-between" class="mt-20">
        <i-button
          :text="config[step].left.text"
          width="145"
          fs="17"
          custom-class="auto-trade-btn"
          @click="onCommand(config[step].left.command)"
        />
        <i-button
          :text="config[step].right.text"
          width="190"
          fs="17"
          @click="onCommand(config[step].right.command)"
        />
      </el-row>
    </el-form>
    <section v-else class="auto-trading-block ta-c">
      <el-row type="flex" justify="space-between">
        <span>Торговаться до:</span>
        <b>{{ form.price | numToLocaleString }} р/т</b>
      </el-row>
      <el-row type="flex" justify="space-between">
        <span>За объем:</span>
        <b>{{ form.weight | numToLocaleString }} т</b>
      </el-row>
      <i-button
        :text="config[step].left.text"
        width="260"
        fs="17"
        custom-class="cancel-trading-btn mt-40"
        @click="onCommand(config[step].left.command)"
      />
    </section>
    <el-row class="mt-60 mb-20">
      <tender-counter :counter="counter" />
    </el-row>
  </el-col>
</template>

<script>
import { mapActions } from 'vuex'
import { tenderMakeRateRules, config } from '@/components/Tender/constants'
import IButton from '@/components/Ui/Button'
import TenderCounter from './TenderCounter'

export default {
  name: 'TenderMakeRate',
  components: { TenderCounter, IButton },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    counter: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      step: 0,
      form: {
        price: undefined,
        weight: undefined
      },
      rules: tenderMakeRateRules,
      config
    }
  },
  methods: {
    ...mapActions('offers', ['CREATE']),
    createOffer() {
      this.$refs.tenderMakeRateForm.validate(valid => {
        if (valid) {
          const data = {
            ...this.form,
            tender_id: this.item.id,
            fetch: { tender_id: this.item.id }
          }
          this.CREATE(data).then(res => {
            if (res !== 'error') this.$refs.tenderMakeRateForm.resetFields()
          })
        }
      })
    },
    startAutoTrading() {
      this.$refs.tenderMakeRateForm.validate(valid => {
        if (valid) {
          this.step = 2
        }
      })
    },
    goNext() {
      this.step = 1
    },
    goBack() {
      this.step = 0
      this.form.weight = undefined
      this.form.price = undefined
    },
    onCommand(command) {
      this[command]()
    }
  }
}
</script>

<style lang="sass">
.tender-rate
  .i-components.el-form--label-top
    .el-form-item__label
      font-size: 16px
      padding-bottom: 10px
  .auto-trade-btn, .auto-trade-btn:focus
    background-color: #C1AE00
    border-color: #C1AE00
    &:hover
      background-color: $goldenColor
      border-color: $goldenColor
  .cancel-trading-btn, .cancel-trading-btn:focus
    height: 43px
    background-color: $redColor
    border-color: $redColor
    &:hover
      background-color: #ff5555
      border-color: #ff5555
  .auto-trading-block
    border: 2px solid $redColor
    padding: 25px 20px
    border-radius: 10px
    font-size: 25px
    line-height: 40px
    color: #000
</style>
