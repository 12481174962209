<template>
  <i-dialog :name="name" @close="beforeClose">
    <tender-modal-title slot="title" :item="item" />
    <tender-counter v-if="start" start :counter="counter" />
    <template v-else>
      <tender-for-bidder v-if="isBidder" :item="item" :counter="counter" />
      <tender-for-vendor v-else :item="item" :counter="counter" />
    </template>
    <el-row
      v-if="!isBidder || (isBidder && counter > 0)"
      slot="footer"
      class="ta-l"
    >
      <tender-responsible :item="item" />
    </el-row>
  </i-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import IDialog from '@/components/Ui/Dialog'
import TenderModalTitle from './TenderModalTitle'
import TenderCounter from './TenderCounter'
import TenderResponsible from './TenderResponsible'
import TenderForBidder from './TenderForBidder'
import TenderForVendor from './TenderForVendor'

export default {
  name: 'TenderModal',
  components: {
    TenderForVendor,
    IDialog,
    TenderModalTitle,
    TenderCounter,
    TenderForBidder,
    TenderResponsible
  },
  data() {
    return {
      name: 'tender-modal',
      start: true,
      counter: 0,
      timer: null
    }
  },
  watch: {
    item: {
      immediate: true,
      handler(val) {
        if (val?.utime_start && val?.utime_stop) {
          this.setCounter(val.utime_start, val.utime_stop)
        }
      }
    }
  },
  computed: {
    ...mapGetters({ getDialog: 'popups/getDialog' }),
    item() {
      return this.getDialog(this.name).data || {}
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    onClick() {
      this.setDialog({ name: this.name })
    },
    setCounter(start, stop) {
      let counter = start - Math.ceil(Date.now() / 1000)

      if (counter <= 0) {
        counter = stop - Math.ceil(Date.now() / 1000)
        this.start = false
      }

      if (counter > 0) {
        this.counter = counter
        this.checkTimer()
      } else {
        this.counter = 0
        clearTimeout(this.timer)
      }
    },
    checkTimer() {
      if (this.counter) {
        this.timer = setTimeout(() => {
          --this.counter
          this.checkTimer()
        }, 1000)
      } else this.setCounter()
    },
    beforeClose() {
      clearTimeout(this.timer)
      this.start = true
      this.counter = 0
      this.timer = null
    }
  },
  beforeDestroy() {
    this.beforeClose()
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.el-dialog.tender-modal
  max-width: 1200px
  margin-top: 3vh !important
  @include to(1024px)
    margin-top: 0 !important
  .el-dialog__body
    min-height: 500px
    box-sizing: border-box
    padding-bottom: 0
    padding-right: 10px
    @include to(768px)
      padding-right: 20px
      height: auto
</style>
