<template>
  <section>
    <el-row class="mb-40">
      <el-select v-model="type" class="mr-50">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-button type="text" @click="$emit('toggle')">
        Показать результат тендера
      </el-button>
    </el-row>
    <component
      :is="component"
      :loading="isLoading"
      :participants="participants"
      :selection="false"
      :secondary="[1, 3].includes(this.type)"
      height="300px"
    />
  </section>
</template>

<script>
import BiddersTable from '@/components/TablesFormsFilters/BiddersTable'
import BiddersGroupTable from '@/components/TablesFormsFilters/BiddersGroupTable'
import ApiFactory from '@/api/ApiFactory'
import { filterParticipantsOptions } from '../constants'
import { mapActions, mapGetters } from 'vuex'

const UsersApi = ApiFactory.get('approvedUsers')

export default {
  name: 'TenderVendorParticipants',
  components: { BiddersTable, BiddersGroupTable },
  props: {
    id: {
      type: Number,
      default: 0
    },
    groups: {
      type: Array,
      default: () => []
    },
    users: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      type: 0,
      loading: false,
      options: filterParticipantsOptions,
      anotherUsers: []
    }
  },
  computed: {
    ...mapGetters('biddersGroups', ['getList', 'getLoading']),
    isLoading() {
      return this.getLoading() || this.loading
    },
    component() {
      return [0, 1].includes(this.type)
        ? 'bidders-table'
        : 'bidders-group-table'
    },
    participants() {
      let participants = []
      switch (this.type) {
        case 0:
          participants = this.users
          break
        case 1:
          participants = this.anotherUsers
          break
        case 2:
          participants = this.groups
          break
        case 3:
          participants = this.getList
          break
      }
      return participants
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        if (val) this.getBidders()
      }
    }
  },
  methods: {
    ...mapActions('biddersGroups', ['FETCH']),
    getBidders() {
      this.loading = true
      const exclude_id = this.users.flatMap(b => b.id).join(',')
      UsersApi.getList({ exclude_id, 'per-page': 999999 })
        .then(({ data }) => (this.anotherUsers = data))
        .catch(err => this.errHandler(err))
        .finally(() => (this.loading = false))
      this.getBiddersGroups()
    },
    getBiddersGroups() {
      const exclude_id = this.groups?.flatMap(b => b.id).join(',')
      this.FETCH({ 'per-page': 999999, exclude_id })
    }
  }
}
</script>
