<template>
  <el-col :sm="config" :xs="24" class="tender-rate-info">
    <tender-rate-table
      :title="!isBidder && finish ? 'Победители:' : 'Рабочие ставки:'"
      :rates="offers.winningOffers"
      :weight="weight"
      :showLink="!isBidder && finish"
      :finish="finish"
      class="mb-60"
      v-on="$listeners"
    />
    <tender-rate-table
      v-if="offers.losingOffers.length"
      :title="!isBidder && finish ? 'Проигравшие:' : 'Непроходные ставки:'"
      :rates="offers.losingOffers"
      :finish="finish"
      :weight="weight"
      :red="true"
    />
  </el-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TenderRateTable from './TenderRateTable'
import { phoneRegex } from '@/helpers/functions'

export default {
  name: 'TenderRateInfo',
  components: { TenderRateTable },
  props: {
    id: {
      type: Number,
      default: 0
    },
    weight: {
      type: String,
      default: ''
    },
    finish: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => ({ span: 14, offset: 2 })
    },
    users: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      tenderInterval: null
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getOffers()
          this.tenderInterval = setInterval(() => {
            if (this.finish) clearInterval(this.tenderInterval)
            else this.getOffers()
          }, 7000)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getList: 'offers/getList',
      getLoading: 'offers/getLoading'
    }),
    offers() {
      const winningOffers = []
      const losingOffers = []
      this.getList.map(offer => {
        const { is_winner, user_created_id, price, weight } = offer
        let namePhone, orgInfo
        let user = 'Участник'
        if (this.isBidder && this.user.id === user_created_id) {
          user = 'Ваша ставка'
        }
        if (!this.isBidder) {
          const userInfo = this.users[user_created_id]
          if (userInfo?.contract) user = `Договор № ${userInfo.contract}`
          if (userInfo?.organization) {
            orgInfo = [userInfo?.organization.name, userInfo?.organization.inn]
          }
          if (userInfo?.first_name) {
            namePhone = [
              `${userInfo.first_name} ${userInfo.last_name}.`,
              phoneRegex(userInfo.login)
            ]
          }
        }
        const newOffer = { price, weight, user, namePhone, orgInfo }
        if (is_winner) winningOffers.push(newOffer)
        else losingOffers.push(newOffer)
      })
      return { winningOffers, losingOffers }
    },
    loading() {
      return this.getLoading()
    }
  },
  methods: {
    ...mapActions('offers', ['FETCH']),
    getOffers() {
      this.FETCH({ tender_id: this.id })
    }
  },
  beforeDestroy() {
    clearInterval(this.tenderInterval)
  }
}
</script>

<style lang="sass">
@import '~@/assets/styles/mixins.sass'

.tender-rate-info
  overflow-y: auto
  height: 460px
  @include to(768px)
    height: auto
</style>
