<template>
  <el-row type="flex" align="middle" class="h-100">
    <el-col class="tender-counter black ta-c">
      <p v-if="counter > 0" class="fs-20 m-0">
        {{ `До ${start ? 'начала' : 'окончания'} торгов` }}
      </p>
      <p class="counter m-0 mt-20">{{ counter | toTimer(true) }}</p>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'TenderCounter',
  props: {
    start: {
      type: Boolean,
      default: false
    },
    counter: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="sass">
.tender-counter
 .counter
   font-size: 40px
   line-height: 1
</style>
