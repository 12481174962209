<template>
  <section>
    <template v-if="counter > 0">
      <tender-make-rate :item="item" :counter="counter" />
      <tender-rate-info
        :id="item.id"
        :weight="item.load_weight_total | numToLocaleString"
      />
    </template>
    <tender-bidder-result v-else :item="item" />
  </section>
</template>

<script>
import TenderMakeRate from './TenderMakeRate'
import TenderRateInfo from './TenderRateInfo'
import TenderBidderResult from './TenderBidderResult'

export default {
  name: 'TenderForBidder',
  components: { TenderMakeRate, TenderRateInfo, TenderBidderResult },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    counter: {
      type: Number,
      default: 0
    }
  }
}
</script>
