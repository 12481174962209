<template>
  <el-row :class="['tender-rate-table', { 'trt-red': red }]">
    <el-row type="flex" justify="space-between" class="title fw-b mb-15">
      <el-col>{{ title }}</el-col>
      <el-col v-if="showLink" class="ta-r">
        <el-button type="text" @click="$emit('toggle')">
          Показать кому был доступен тендер
        </el-button>
      </el-col>
    </el-row>
    <el-table
      v-if="rates.length"
      v-loading="loading"
      :data="rates"
      stripe
      style="width: 100%"
    >
      <el-table-column
        v-for="el in fields"
        :key="el.prop"
        :prop="el.prop"
        :label="el.label"
        :width="el.width + 'px'"
      >
        <div slot-scope="scope" :class="{ 'fw-b': scope.row.me }">
          <div v-for="item in getField(scope.row[el.prop])" :key="item">
            {{ item }}
            <span v-if="el.prop === 'price'"> руб</span>
          </div>
        </div>
      </el-table-column>
    </el-table>
    <span v-else class="fs-20 t-greyDark">Ставок нет</span>
  </el-row>
</template>

<script>
import { trtFields, trtVendorFields } from '@/components/Tender/constants'

export default {
  name: 'TenderRateTable',
  props: {
    title: {
      title: String,
      default: ''
    },
    red: {
      title: Boolean,
      default: false
    },
    showLink: {
      title: Boolean,
      default: false
    },
    loading: {
      title: Boolean,
      default: false
    },
    rates: {
      title: Array,
      default: () => []
    },
    finish: {
      type: Boolean,
      default: false
    },
    weight: {
      type: String,
      default: ''
    }
  },
  computed: {
    fields() {
      return !this.isBidder && this.finish ? trtVendorFields : trtFields
    }
  },
  methods: {
    getField(row) {
      return Array.isArray(row) ? row : [row]
    }
  }
}
</script>

<style lang="sass">
.tender-rate-table
  .title
    font-size: 25px
    line-height: 28px
    color: $greenColor
  .cell
    font-size: 15px
    line-height: 17px
  &.trt-red
    th
      background: $redColor
    .title
      color: $redColor
</style>
