<template>
  <el-col
    v-if="hasOtvetstvenniy"
    :sm="bidder ? 24 : 10"
    :xs="24"
    class="fs-20 blackDark"
  >
    <p class="m-0 pb-5">Ответственный за торги:</p>
    <p class="m-0">
      <b v-if="item.otvetstvenniy_name.length > 0">
        {{ item.otvetstvenniy_name }}
      </b>
      <span>тел. </span>
      <b v-if="item.otvetstvenniy_phone.length > 0">
        {{ item.otvetstvenniy_phone }}
      </b>
    </p>
  </el-col>
</template>

<script>
export default {
  name: 'TenderResponsible',
  props: {
    item: {
      item: {
        type: Object,
        default: () => ({})
      }
    },
    bidder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasOtvetstvenniy() {
      return (
        this.item?.otvetstvenniy_phone?.length > 0 ||
        this.item?.otvetstvenniy_name?.length > 0
      )
    }
  }
}
</script>
