<template>
  <section>
    <el-row class="fs-16 mb-50 blackDark">
      <span class="mr-20">Начальная цена:</span>
      <b v-if="item.price_start">{{ item.price_start }} р/т</b>
    </el-row>
    <el-row type="flex">
      <el-col :sm="12" :xs="24">
        <p :class="['fs-25 tt-up fw-b mb-30 mt-0', myOffer.color]">
          Вы
          <span>{{ myOffer.text }}</span>
          в этом тендере
        </p>
        <template v-if="myOffer.data">
          <el-row class="fs-15 mb-15">
            <el-col :span="9"><b>Ваша ставка,</b> руб:</el-col>
            <el-col :span="4">{{ myOffer.data.price }}</el-col>
          </el-row>
          <el-row class="fs-15">
            <el-col :span="9"><b>Тоннаж перевозки,</b> тонн:</el-col>
            <el-col :span="4">{{ myOffer.data.weight }}</el-col>
          </el-row>
        </template>
      </el-col>
      <el-col v-if="myOffer.winner" :sm="12" :xs="24" class="ml-10">
        <tender-responsible :item="item" bidder />
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TenderResponsible from './TenderResponsible'

export default {
  name: 'TenderForVendor',
  components: { TenderResponsible },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    item: {
      immediate: true,
      handler(val) {
        if (val?.id) this.getOffers()
      }
    }
  },
  // "price":0.7,"weight":2000,"is_winner":1,

  computed: {
    ...mapGetters('offers', ['getList']),
    myOffer() {
      const config = {
        color: 't-greyDark',
        text: 'не участвовали',
        winner: false
      }
      const myOffer = this.getList.find(
        offer => this.user.id === offer.user_created_id
      )
      if (myOffer) {
        config.color = myOffer.is_winner ? 't-greenDark' : 'declined'
        config.text = myOffer.is_winner ? 'победили' : 'проиграли'
        config.winner = myOffer.is_winner
        config.data = { price: myOffer.price, weight: myOffer.weight }
      }
      return config
    },
    loading() {
      return this.getLoading()
    }
  },
  methods: {
    ...mapActions('offers', ['FETCH']),
    getOffers() {
      this.FETCH({ tender_id: this.item.id })
    }
  }
}
</script>
