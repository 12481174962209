import { render, staticRenderFns } from "./TenderBidderResult.vue?vue&type=template&id=44548b3f&"
import script from "./TenderBidderResult.vue?vue&type=script&lang=js&"
export * from "./TenderBidderResult.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports