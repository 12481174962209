<template>
  <el-table
    v-loading="loading"
    :data="tableData"
    empty-text="Нет данных"
    stripe
    :key="updateTable"
    :class="['mt-20 tender-table', { 'at-empty': !tableData.length }]"
    style="width: 100%"
    @row-click="handleCurrentChange"
  >
    <el-table-column
      v-for="el in tableFields"
      :key="el.prop"
      :prop="el.prop"
      :width="el.width + 'px'"
      :min-width="el.minWidth ? `${el.minWidth}px` : ''"
    >
      <template slot="header">
        {{ el.label }}
        <TableCell :data="el.span" />
      </template>
      <LoaderWrapper
        slot-scope="props"
        :loading="loadingCounters && el.loading"
        spinner="el-icon-loading"
        :background="getBG(props.$index)"
        custom-class="mini-spinner"
      >
        <TableCell
          :propsk="props.$index"
          :data="props.row[el.prop]"
          :row="props.row"
          :prop-key="el.prop"
          :status="props.row.status"
          :counter="getCounter(el.prop, props.row)"
        />
      </LoaderWrapper>
    </el-table-column>
    <el-table-column v-if="!archive" width="36px">
      <div class="edit-icon" />
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import TableCell from '@/components/Ui/TableCell'
import LoaderWrapper from '@/components/Wrappers/LoaderWrapper'
import { getTableFields } from './constants'

export default {
  name: 'TenderTable',
  components: { TableCell, LoaderWrapper },
  props: {
    archive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      getLib: 'libs/getLib',
      tableData: 'tenders/getList',
      getLoading: 'tenders/getLoading'
    }),
    loading() {
      return this.getLoading()
    },
    loadingCounters() {
      return this.getLoading('Counters')
    },
    tableFields() {
      const type = this.isMobile
        ? 'Mobile'
        : this.isTablet
        ? 'Tablet'
        : this.isSmallDesktop
        ? 'SmallDesktop'
        : ''
      return getTableFields(this.archive, this.isBidder, type)
    },
    updateTable() {
      if (this.isMobile) return 'mobile'
      else if (this.isTablet) return 'tablet'
      else if (this.isSmallDesktop) return 'smallDesktop'
      else return 'desktop'
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    getBG(index) {
      return index % 2 === 0
        ? 'rgba(255, 255, 255, 0.9)'
        : 'rgba(239, 239, 239, 0.9)'
    },
    handleCurrentChange(row) {
      const { status } = this.getCounter('status_left', row, false)
      if (this.isBidder || this.archive || status === 'accepted') {
        this.setDialog({
          name: 'tender-modal',
          visible: true,
          data: row
        })
      } else if (status === 'declined') {
        this.$router.push({ name: 'TenderEdit', params: { id: row.id } })
      }
    },
    getCounter(prop, row, check = true) {
      if (
        check &&
        (prop !== 'status_left' || this.archive || this.loadingCounters)
      )
        return null

      const { utime_start, utime_stop } = row
      let status = 'declined'
      let counter = utime_start - Math.ceil(Date.now() / 1000)

      if (counter <= 0) {
        counter = utime_stop - Math.ceil(Date.now() / 1000)
        status = 'accepted'
      }

      if (counter < 0) {
        status = 'golden'
        counter = 'завершен'
      } else counter = this.$options.filters.toTimer(counter, true, false)

      return { status, counter }
    }
  }
}
</script>

<style lang="sass">
.at-empty:before
  background-color: unset
.tender-table
  .el-table__row
    cursor: pointer
    .date_created .cell div
      font-size: 15px
      line-height: 18px
      color: $textTableBody
    .direction
      color: $textTableBody
      text-align: left
      word-break: break-word
    .direction div:first-of-type
      font-weight: bold
    .prices .cell div
      font-weight: bold
      font-size: 20px
      line-height: 22px
      text-align: center
      color: $textTableBody
    .volume
      div:first-of-type
        font-weight: bold
    .price_start,
    .price_winning,
    .counter_best_price,
    .counter_my_price,
    .counter_bidders_count,
    .load_weight_total
      font-weight: bold
</style>
